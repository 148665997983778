import CardWrapper from "components/Common/CardWrapper";
import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import {
  Button,
  ButtonGroup,
  CardBody, CardTitle, Spinner
} from "reactstrap";
import FeatherIcon from "feather-icons-react";
import { getClientTransactionGraphAPI } from "apis/forex/ib";
import classNames from "classnames";

export default function ClientTransactionGraph() {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [type, setType] = React.useState("yearly");
  const [data, setData] = React.useState([{
    name: "Deposits",
    data: [],
  }, {
    name: "Withdrawals",
    data: [],
  }]);

  useEffect(() => {
    setLoading(true);
    // call api
    getClientTransactionGraphAPI({
      type,
    }).then((res) => {
      if (res.isSuccess) {
        if (res?.result?.deposits && res?.result?.withdrawals) {
          setData([{
            name: "Deposits",
            data: [res?.result?.deposits],
          }, {
            name: "Withdrawals",
            data: [res?.result?.withdrawals],
          }]);
        } else {
          setData([{
            name: "Deposits",
            data: [0],
          }, {
            name: "Withdrawals",
            data: [0],
          }]);
        }
      }
    }).catch((err) => {
      console.log(err);
    }).finally(() => {
      setLoading(false);
    });
  }, [type]);

  return (
    <CardWrapper className={"glass-card shadow mt-2"}>
      <CardTitle className="d-flex align-items-center justify-content-between">
        <div>
          <h5 className="color-primary d-flex align-items-center">
            <FeatherIcon
              className="color-primary mx-1"
              icon="users"
            />
            {t("Client Transactions")}
          </h5>
        </div>
        <div>
          <ButtonGroup>
            <Button
              className={classNames("btn btn-light border-0 ", {
                "shadow-lg text-white color-bg-btn": type === "yearly",
              })}
              onClick={() => {
                setType("yearly");
              }}>Yearly</Button>
            <Button 
              className={classNames("btn btn-light border-0 ", {
                "shadow-lg text-white color-bg-btn": type === "monthly",
              })}
              onClick={() => {
                setType("monthly");
              }}>Monthly</Button>
            <Button 
              className={classNames("btn btn-light border-0 ", {
                "shadow-lg text-white color-bg-btn": type === "daily",
              })}
              onClick={() => {
                setType("daily");
              }}>Daily</Button>
          </ButtonGroup>
        </div>
      </CardTitle>
      <CardBody className="text-center">
        {
          loading ? <Spinner /> : <>
            <ReactApexChart
              type="bar"
              series={data}
              options={{
                chart: {
                  type: "bar",
                },
                dataLabels: {
                  enabled: false,
                },
                plotOptions: {
                  bar: {
                    horizontal: true,
                  }
                }, 
                tooltip: {
                  shared: true,
                  intersect: false
                },     
                labels: ["Deposits", "Withdrawals"],
                xaxis: {
                  categories: ["Transactions"]
                }
              }}
              width="100%"
            />
          </>
        }
      </CardBody>
    </CardWrapper>
  );
}
