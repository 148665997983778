/* eslint-disable indent */
import { AvForm } from "availity-reactstrap-validation";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Select, { components } from "react-select";
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { getAvailableInvestorAccountsAPI } from "apis/forex/accounts";
import { postLinkRequestAPI } from "apis/forex/requests";
import { showErrorNotification, showSuccessNotification } from "store/actions";
import SearchableComponent from "components/Common/SearchableComponent";
import { getAllProfilesAPI } from "apis/forex/mam_pam";
import CustomSelect from "components/Common/CustomSelect";

const { SingleValue, Option } = components;

const AccountSelect = (props) => {
  const { t } = useTranslation();
  const { layoutMode } = useSelector(state => state.Layout);

  const IconSingleValue = (props) => (
    <SingleValue {...props}>
      <div
        className="color-primary font-weight-bold border-0"
        style={{ fontSize: "12px" }}
      ></div>
      <div style={{ fontWeight: "bold" }}>{props.data.label}</div>
    </SingleValue>
  );

  const IconOption = (props) => <Option {...props}>{props.data.label}</Option>;

  const customStyles = {
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#495057",
      padding: 0,
      paddingRight: "5px",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none"
    }),
    control: (provided) => {
      if (layoutMode === "dark") {
        return {
          ...provided,
          backgroundColor: "#242632",
          border: 0,
          boxShadow: "0 0.125rem 0.25rem #0B182F",
          color: "#adb5bd",
          height: "100%",
        };
      }
      return {
        ...provided,
        height: "100%",
      };
    },
    menu: (provided) => ({
      ...provided,
      backgroundColor: layoutMode === "dark" ? "#242632" : "white",
      color: layoutMode === "dark" ? "#adb5bd" : "#495057",
      zIndex: 3,
    }),
    option: (provided, state) => ({
      ...provided,
      display: state.isDisabled ? "none" : "flex",
      flexDirection: "row",
      alignItems: "center",
      color: layoutMode === "dark" ? "#adb5bd" : "#495057",
    }),
    singleValue: (provided) => {
      return {
        ...provided,
        flexDirection: "row",
        alignItems: "center",
        color: layoutMode === "dark" ? "#adb5bd" : "#495057",
      };
    },
  };

  return (
    <>
      <div style={{ backgroundColor: "transparent" }}>
        <CustomSelect
          {...props}
          className="basic-single color-primary shadow border-0"
          placeholder={t("Select Account")}
          onInputChange={(input) => {
            if (input !== "") {
              if (!/^-?\d*\.?\d*$/.test(input)) {
                return "";
              }
            }
            return input;
          }}
          components={{
            SingleValue: IconSingleValue,
            Option: IconOption,
          }}
          options={props.options}
        />
      </div>
    </>
  );
};

const LinkInvestorToProfile = ({ isOpen, toggle }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedInvestor, setSelectedInvestor] = useState(null);
  const [loading, setLoading] = useState(false);
  const [masterTradingAccount, setMasterTradingAccount] = useState(null);
  const [investorAccounts, setInvestorAccounts] = useState([]);
  const [error, setError] = useState(false);
  const [account, setAccount] = useState();

  const getAvailableInvestorAccounts = () => {
    setLoading(true);
    getAvailableInvestorAccountsAPI().then((res) => {
      setInvestorAccounts(res.result);
    }).catch((err) => {
      console.log(err);
      setInvestorAccounts([]);
    }).finally(() => {
      setLoading(false);
    });
  };

  const handleInvestorChange = (selectedOption) => {
    setSelectedInvestor(selectedOption);
  };

  const handleSubmit = () => {
    const payload = {
      params: {
        investorId: selectedInvestor.value,
        masterTradingAccountId: masterTradingAccount,
      }
    };
    setLoading(true);
    postLinkRequestAPI(payload).then((res) => {
      if (res.isSuccess) {
        toggle();
        dispatch(showSuccessNotification("Link request sent successfully"));
      }
    }
    ).catch((err) => {
      dispatch(showErrorNotification(err?.message || "Something went wrong"));
    }
    ).finally(() => {
      setLoading(false);
    }); 
  };

  useEffect(() => {
    getAvailableInvestorAccounts();
  }, []);

  return (
    <Modal centered isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4">
        {t("Link Investor To SP")}
      </ModalHeader>
      <ModalBody>
        <AvForm
          onValidSubmit={handleSubmit}
        >
          <Label className="form-label mb-2">{t("Account")}</Label>
          <AccountSelect
            options={investorAccounts.map((item) => ({
              value: item._id,
              label: item.login,
            }))}
            value={selectedInvestor}
            onChange={handleInvestorChange}
          />
          {/* <SearchHandler
            value={masterTradingAccount}
            setValue={(value)=> setMasterTradingAccount(value)}
            placeholder={t("Search")}
            label="Master Account"
            handler={async (payload)=>{
              const resp = await searchAccountAPI(payload);
              if (resp.isSuccess) {
                return resp.result;
              }
              throw new Error(resp.message);
            }}
            type={"MAM_MASTER"}
            setIsError={setError}
            setAccount={setAccount}
          /> */}
          <div>
            <SearchableComponent
              placeholder={("Select Profile Account")}
              label={"Profile Account"}
              name={"profileId"}
              onChange={(e) => {
                if (setAccount) {
                  if (e?.value) {
                    setMasterTradingAccount(e.value);
                    setAccount(e.value);
                  } else {
                    setMasterTradingAccount(e);
                    setAccount(e);
                  }
                } else {
                  e?.value ? setMasterTradingAccount(e.value) : setMasterTradingAccount(e);
                }
              }}
              getData={
                async () => getAllProfilesAPI({
                  params: {
                    page: 1,
                    limit: 1000,
                  }
                }).then((res) => (res?.result?.docs || []))
              }
              mapper={(doc)=> (
                {
                  label: `${doc?.username ?? "-"} - ${doc?.masterTradingAccountId?.recordId}`,
                  value: doc?.masterTradingAccountId?._id
                }
              )}
              value={masterTradingAccount}
            />
          </div>
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex gap-2 mx-auto">
          <Button onClick={toggle}>{t("Cancel")}</Button>
          <Button
            disabled={
              !selectedInvestor || !masterTradingAccount || error || loading
            }
            onClick={handleSubmit}
            className="btn btn-success"
            type="submit"
          >
            {t("Link")}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default LinkInvestorToProfile;
