// import { fetchCompanyBankAccounts } from "apis/bankAccounts";
import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import { Label } from "reactstrap";
import CustomSelect from "components/Common/CustomSelect";
import { useDispatch, useSelector } from "react-redux";
import { fetchWallets, getAccountsStart } from "store/actions";

export default function AsiaBanks(props) {
  const { t, setIsFirstStepValid, setBaseCurrency, type } = props;

  useEffect(()=>{
    setIsFirstStepValid(true);
  }, []);  
  return (
    <></>
  );
}
