import React from "react";
import { Col, Row } from "reactstrap";
import MonthlyCommissionGraph from "./Report/MonthlyCommissionGraph";
import ClientTransactionGraph from "./Report/ClientTransactionGraph";

export default function IBReport() {
  return (
    <div className="my-2">
      <Row className="my-2">
        <Col xs={12} lg={6} >
          <MonthlyCommissionGraph />
        </Col>
        <Col xs={12} lg={6}>
          <ClientTransactionGraph />
        </Col>
      </Row>
      {/* <Row className="my-2">
        <IBStatsTotal />
      </Row> */}
    </div>
  );
}
