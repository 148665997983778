import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import { Label } from "reactstrap";
import CustomSelect from "components/Common/CustomSelect";

export default function WireTransfer(props) {
  const {
    t,
    setIsFirstStepValid,
    setBaseCurrency,
    isLocal,
    banks
  } = props;
  const [bankAccount, setBankAccount] = useState(null);
  
  useEffect(() => {
    if (bankAccount) {
      setIsFirstStepValid(true);
    }
  }, [bankAccount]);

  return (
    <div className="my-3">
      <CustomSelect
        name="bankAccount"
        onChange={(e) => {
          setBankAccount(e.value);
          setBaseCurrency(e.value.currency);
        }}
        required
        placeholder="Select Bank Account"
        options={banks.map((bank) => {
          console.log("bank: ", bank);
          return {
            label:`${bank.bankName}`,
            value: bank
          };
        })}
      >
      </CustomSelect>
      {
        bankAccount && (
          <AvForm className="mt-4">
            <h5 className="mb-4">{t("Payment details")}</h5>
            <div>
              <Label>{t("Beneficiary Name")}</Label>
              <AvField
                type="text"
                name="accountHolderName"
                value={bankAccount.accountHolderName}
                validate={{ required:true }}
                disabled={true}
              >
              </AvField>
            </div>
            <div>
              <Label>{t("Bank Name")}</Label>
              <AvField
                type="text"
                name="bankName"
                value={bankAccount.bankName}
                validate={{ required:true }}
                disabled={true}
              >
              </AvField>
            </div>
            <div>
              <Label>{t("Account Number")}</Label>
              <AvField
                type="text"
                name="accountNumber"
                value={bankAccount.accountNumber}
                validate={{ required:true }}
                disabled={true}
              >
              </AvField>
            </div>
            <div>
              <Label>{t("IBAN")}</Label>
              <AvField
                type="text"
                name="IBAN"
                value={bankAccount.iban}
                validate={{ required:true }}
                disabled={true}
              >
              </AvField>
            </div>
            <div>
              <Label>{t("Address")}</Label>
              <AvField
                type="text"
                name="address"
                value={bankAccount.address}
                validate={{ required:true }}
                disabled={true}
              >
              </AvField>
            </div>
            <div>
              <Label>{t("Swift/IFSC Code")}</Label>
              <AvField
                type="text"
                name="swiftCode"
                value={bankAccount.swiftCode}
                validate={{ required:true }}
                disabled={true}
              >
              </AvField>
            </div>
            <div>
              <Label>{t("Currency")}</Label>
              <AvField
                type="text"
                name="currency"
                disabled={true}
                value={bankAccount.currency}
                validate={{ required:true }}
              >
              </AvField>
            </div>
            <p>{t("Bank Account")}</p>
            <p className="text-muted">
              {isLocal &&
                <>
                  <h6 className="text-bold">
                    {t("Notice to Depositor")}
                  </h6>
                  <ol style={{ listStyleType: "decimal" }}>
                    <li>
                      {t("Please complete your payment within the time stipulated (1 hour) If the order is canceled due to a timeout, you can contact our customer service or account manager.")}
                    </li>
                    <li>
                      {t("Should there be three canceled orders by the depositor actively or due to timeout on a single day, the system will restrict you from depositing further.")}
                      <br></br>
                      {t("Please complete the payment with your real name payment account (e.g., bank account, Upi account, or Wallet payment account). If you make a payment using a falsified name or with a different account name you will be asked for a video of the buyer.")}
                    </li>
                    {t("The buyer shall bind a valid payment tool registered with the real name used on the Platform for payment.")}
                    <li>
                      {t("For timely transactions, please choose real-time transfer options such as UPI balance to top up balance.")}
                    </li>
                    <li>
                      {t("Transaction Limit is set to  INR 2,00,000/- each transaction.")}
                    </li>
                    <li>
                      {t("The client shall not leave any information in the remittance note; otherwise, the transaction is entitled to be cancelled.")}
                    </li>
                  </ol>
                </>
              }
            </p>
          </AvForm>
        )
      }
    </div>
  );
}
