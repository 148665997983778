import Slider from "pages/Crypto/Dashboard/Slider";
// eslint-disable-next-line object-curly-newline
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMamProfilesInitAction } from "store/forex/mamProfile/actions";
import {
  getAccountDetailsInit,
  getClosePositionsStart,
  getOpenPositionsStart,
} from "store/actions";
import SpJourney from "./SpJourney";
import Investors from "./Investors";
import SpAccountDetail from "./SpAccountDetail";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import SpTradingActivity from "./SpTradingActivity";
import CpTransactions from "../CpTransactions";
import Silder from "../Silder";

const SpPortalDashboard = () => {
  const { type } = useParams();
  const [showSlider] = useState(true);
  const dispatch = useDispatch();
  const { profiles, selectedMamAccount: selectedAccount } = useSelector(
    (state) => state.forex.mamProfile
  );

  const selectedTradingAccount = useMemo(() => {
    if (selectedAccount) {
      const filteredAccount = profiles.filter(
        (obj) => obj.masterTradingAccountId.login === selectedAccount.label
      )[0];
      return filteredAccount;
    }
    return null;
  }, [selectedAccount]);

  useEffect(() => {
    dispatch(getMamProfilesInitAction({ params: { type: "sp" } }));
  }, []);

  useEffect(() => {
    if (selectedTradingAccount) {
      dispatch(
        getAccountDetailsInit(selectedTradingAccount.masterTradingAccountId._id)
      );
    }
  }, [selectedTradingAccount]);

  useEffect(() => {
    if (selectedTradingAccount) {
      dispatch(
        getOpenPositionsStart({
          _id: selectedTradingAccount.masterTradingAccountId._id,
          page: 1,
          limit: 10,
        })
      );
      dispatch(
        getClosePositionsStart({
          _id: selectedTradingAccount.masterTradingAccountId._id,
          page: 1,
          limit: 10,
        })
      );
    }
  }, [selectedTradingAccount]);

  return (
    <>
      <div className="pt-3">
        <Silder />
      </div>
      <div
        style={{
          marginBottom: "10%",
        }}
      >
        <div className="pt-3">
          <SpJourney />
        </div>
        <div>
          <SpAccountDetail
            selectedTradingAccount={selectedTradingAccount}
            profiles={profiles}
            operations={true}
          />
        </div>
        <div className="pt-5">
          <SpTradingActivity
            type={type}
            selectedTradingAccount={selectedTradingAccount}
          />
        </div>
        <div className="pt-5">
          <Investors selectedTradingAccount={selectedTradingAccount} />
        </div>
        <div className="pt-5">
          <CpTransactions
            profile={selectedTradingAccount}
            variant={"InvestorDeposit"}
          />
        </div>
        <div className="pt-5">
          <CpTransactions
            profile={selectedTradingAccount}
            variant={"InvestorWithdraw"}
          />
        </div>
        <div className="pt-5">
          <CpTransactions
            profile={selectedTradingAccount}
            variant={"FeeTradingWithdraw"}
          />
        </div>
      </div>
    </>
  );
};

export default SpPortalDashboard;
