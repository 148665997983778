import { useDispatch, useSelector } from "react-redux";
import { getMamProfilesInitAction } from "store/forex/mamProfile/actions";
// eslint-disable-next-line object-curly-newline
import { useEffect, useState } from "react";
import FeesDeducted from "pages/Forex/Dashboard/InvestorPortal/FeesDeducted";
import InvestorAccountDetail from "pages/Forex/Dashboard/InvestorPortal/InvestorAccountDetail";
import InvestorJourney from "./InvestorJourney";
import Slider from "pages/Crypto/Dashboard/Slider";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import CpTransactions from "../CpTransactions";
import InvestorTradingActivity from "./InvestorTradingActivity";
import MasterDetails from "./MasterTraders/MasterDetails";
import { getAccountsStart } from "store/actions";
import Silder from "../Silder";

const InvestorPortalDashboard = () => {

  const { type } = useParams();
  const [showSlider] = useState(true);
  const dispatch = useDispatch();

  const { profiles } = useSelector((state) => state.forex.mamProfile);
  const { accounts } = useSelector((state) => state.forex?.accounts);
  const { clientData = {} } = useSelector((state) => state.Profile);

  const [selectedProfile, setSelectedProfile] = useState(null);
  const [investorAccounts, setInvestorAccounts] = useState([]);

  useEffect(() => {
    dispatch(getMamProfilesInitAction({ params: { type: "investor" } }));
  }, []); 

  useEffect(() => {
    dispatch(getAccountsStart({}));
  }, []);

  useEffect(() => {
    const newInvestorAccounts = [];
    if (profiles?.length > 0) {
      profiles.forEach((profile) => {
        profile.slaves.forEach((slave) => {
          if (slave.customerId._id === clientData?._id) {
            newInvestorAccounts.push({
              ...profile,
              selectedInvestorAccount: slave,
              investorId: slave._id,
            });
          }
        });
      });
    }
    if (accounts?.length > 0) {
      accounts?.filter(a => a.type === "INVESTOR").forEach((account) => {
        // need to check if account is already added by checking investor id of profiles
        const isAlreadyAdded = newInvestorAccounts.find(
          (item) => item.investorId === account._id
        );
        if (!isAlreadyAdded) {
          newInvestorAccounts.push({
            selectedInvestorAccount: {
              ...account,
              tradingAccountId: account._id,
            },
            investorId: account._id,
            _id: account._id,
          });
        }
      });
    }
    setInvestorAccounts(newInvestorAccounts);
  }, [profiles, accounts]);

  useEffect(() => {
    if (investorAccounts?.length > 0) {
      setSelectedProfile(investorAccounts[0]);
    }
  }, [investorAccounts]);

  return (
    <>
      <div className="pt-3">
        <Silder />
      </div>
      <div style={{ marginBottom: "10%" }}>
        <div className="pt-3">
          <InvestorJourney />
        </div>
        <div className="pt-3">
          <MasterDetails />
        </div>
        <div>
          <InvestorAccountDetail
            selectedProfile={selectedProfile}
            profiles={investorAccounts}
            operations={true}
            setSelectedProfile={setSelectedProfile}
          />
        </div>
        <div className="pt-5">
          <FeesDeducted selectedProfile={selectedProfile} />
        </div>
        <div className="pt-5">
          <CpTransactions
            profile={selectedProfile}
            variant={"InvestorDeposit"}
          />
        </div>
        <div className="pt-5">
          <CpTransactions
            profile={selectedProfile}
            variant={"InvestorWithdraw"}
          />
        </div>
        {
          selectedProfile?.profileType !== 2 && <div className="pt-5">
            <InvestorTradingActivity
              type={type}
              selectedTradingAccount={selectedProfile?.selectedInvestorAccount}
            />
          </div>
        }
      </div>
    </>
  );
};

export default InvestorPortalDashboard;
