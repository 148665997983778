import { getStatementDeals } from "apis/forex/ib";
import { AvField, AvForm } from "availity-reactstrap-validation";
import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  Button,
  Modal,
  Row,
  Col,
  Label,
} from "reactstrap";
import { showErrorNotification } from "store/actions";
import * as XLSX from "xlsx";
import moment from "moment";

export default function DownloadStatement(props) {
  const [loading, setLoading] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const toggle = () => setShowModal(!showModal);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [dateFrom, setDateFrom] = useState(String(moment().subtract(30, "days").format("YYYY-MM-DD")));
  const [dateTo, setDateTo] = useState(String(moment().add(1, "day").format("YYYY-MM-DD")));

  const fetchDeals = (params) => {
    setLoading(true);
    getStatementDeals({
      payload: {
        ...params,
        entry: 1,
        platform: "MT5",
      }
    }).then((res) => {
      if (res?.isSuccess) {
        const docs = res?.result?.docs || [];
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(docs.filter( d => d?.clientDeal?.login === props.clientLogin).map(item => {
          return {
            "IB DEAL": item?.dealId,
            "IB LOGIN": item?.login,
            "IB DEAL DATE": moment(item?.time * 1000).format("YYYY-MM-DDTHH:mm"),
            "Client Deal Id": item?.clientDeal?.dealId,
            "Client Action": item?.clientDeal?.action === 0 ? "Buy" : "Sell",
            "Client Position Id": item?.clientDeal?.positionId,
            "Client Login": item?.clientDeal?.login,
            "Symbol":  (item?.clientDeal?.symbol || "-"),
            "Client Profit":  (item?.clientDeal?.profit || "-"),
            "Client Deal Time": (moment(item?.clientDeal?.time * 1000).format("YYYY-MM-DDTHH:mm") || "-"),
            "Client Volume": props?.entry === 0 ? parseFloat(item?.clientDeal?.volume / 10000)?.toFixed(2) : parseFloat(item?.clientDeal?.volumeClosed / 10000)?.toFixed(2),
            [props.entry === 0 ? "Commission" : "Rebate"]: parseFloat(item?.profit)?.toFixed(3)
          };
        }));
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, `${props.clientLogin}.xlsx`);
        toggle();
      } else {
        throw new Error(res?.message);
      }
    }).catch((err) => {
      dispatch(showErrorNotification(err?.message || "Something went wrong"));
    }).finally(() => {
      setLoading(false);
    });
  };

  const onSubmit = (e, v) => {
    fetchDeals({
      clientLogin: v.login,
      page: v.page,
      limit: v.limit,
      dateFrom: dateFrom,
      dateTo: dateTo,
    });
  };

  const handleDateFrom = (e) => setDateFrom(e.target.value);
  const handleDateTo = (e) => setDateTo(e.target.value);

  return (
    <div>
      <Button
        className={classNames("btn btn-light border-0 text-white color-bg-btn")}
        onClick={toggle}>
        {t("Download Statement")}
      </Button>
      <Modal
        isOpen={showModal}
        toggle={toggle}
        centered={true}
        size="lg"
        className='custom-modal'
      >
        <div className="modal-header">
          <button
            type="button"
            className="close btn btn-soft-dark waves-effect waves-light btn-rounded m-4"
            data-dismiss="modal"
            aria-label="Close"
            onClick={toggle}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h4>{t("Download Statement")}</h4>
          <hr />
        </div>
        <AvForm
          onValidSubmit={onSubmit}
        >
          <div className="modal-body">
            <Row className="mb-3">
              <Col>
                <Label>{t("Login")}</Label>
                <AvField
                  name="login"
                  type="number"
                  className="form-control"
                  value={props.clientLogin}
                  disabled
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Label>{t("Page")}</Label>
                <AvField
                  name="page"
                  type="number"
                  className="form-control"
                  value={1}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Label>{t("Limit")}</Label>
                <AvField
                  name="limit"
                  type="number"
                  className="form-control"
                  value={2000}
                />
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="12" lg="12">
                <AvField
                  className="mt-1 mb-2"
                  type="date"
                  name="dateFrom"
                  label={t("From Date")}
                  value={dateFrom}
                  validate={{ date: { format: "YYYY-MM-DD" } }}
                  onChange={handleDateFrom}
                />
              </Col>
              <Col xs="12" md="12" lg="12">
                <AvField
                  className="mt-1 mb-2"
                  type="date"
                  name="dateTo"
                  label={t("To Date")}
                  validate={{ date: { format: "YYYY-MM-DD" } }}
                  value={dateTo}
                  onChange={handleDateTo}
                />
              </Col>
            </Row>
            <div className='text-center pt-3 p-2'>
              <Button type="submit" color="primary" className=""
                disabled={loading}
              >
                {loading ? t("Loading...") : t("Download")}
              </Button>
            </div>
          </div>
        </AvForm>
      </Modal>
    </div>
  );
}
