/* eslint-disable indent */
import CardWrapper from "components/Common/CardWrapper";
import TableLoader from "components/Common/TableLoader";
import { useTranslation, withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
// eslint-disable-next-line object-curly-newline
import { Th, Thead, Tr } from "react-super-responsive-table";
import { Spinner, Table } from "reactstrap";
import CustomPagination from "components/Common/CustomPagination";
import {
  useEffect, useState
} from "react";
import { getCpTransactions } from "store/forex/cp/actions";

function CpTransactions(props) {
  const {
    profile,
    variant,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.forex.cp.transactions);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [ids, setIds] = useState([]);
  const {
    subPortal
  } = useSelector((state) => state.Layout);
  // const [type, setType] = useState("live");
  const loadDeposits = (page, limit) => {
    if (ids) {
      dispatch(
        getCpTransactions(
          {
            tradingAccountIds: ids,
            // variant: variant,
            type:
              variant === "MasterDeposit" || variant === "InvestorDeposit"
                ? "DEPOSIT"
                : "WITHDRAW",
            // accountType: "LIVE",
            // dateFrom: moment().subtract(1, "months").format("DD-MM-YYYY"),
            // dateTo: moment().format("DD-MM-YYYY"),
            page,
            limit,
          },
          variant
        )
      );
    }
  };

  const getStatusColor = (status) => {
    switch (String(status).toLowerCase()) {
      case "approved":
        return "color-green";
      case "rejected":
        return "color-red";
      default:
        return "";
    }
  };

  useEffect(() => {
    if (ids?.length > 0) {
      loadDeposits(1, sizePerPage);
    }
  }, [sizePerPage, ids]);

  useEffect(() => {
    const isInvestorPortal = subPortal === "INVESTOR";
    if (variant === "InvestorDeposit") {
      const slaves = profile?.slaves?.map((slave) => slave.tradingAccountId);
      !isInvestorPortal ? setIds(slaves) : setIds([profile?.investorId]);
    } else if (variant === "InvestorWithdraw") {
      const slaves = profile?.slaves?.map((slave) => slave.tradingAccountId);
      !isInvestorPortal ? setIds(slaves) : setIds([profile?.investorId]);
    } else if (variant === "FeeTradingWithdraw") {
      const slaves = [profile?.feeTradingAccountId?._id];
      setIds(slaves);
    }
  }, [profile, variant]);

  const columns = [
    {
      text: t("Login"),
      formatter: (val) =>
        `${val.tradingAccountId?.login}`,
    },
    {
      text: t("Client"),
      formatter: (val) =>
        `${val.customerId.firstName} ${val.customerId.lastName}`,
    },
    {
      dataField: "gateway",
      text: t("Gateway"),
      formatter: (val) => <>{val.gateway}</>,
    },
    {
      dataField: "amount",
      text: t("Amount"),
      formatter: (val) => (
        <>
          {val.amount} {val.currency}
        </>
      ),
    },
    {
      dataField: "status",
      text: t("Status"),
      formatter: (val) => (
        <span
          className={`text-capitalize font-weight-bold ${getStatusColor(
            val?.status
          )}`}
        >
          {val.status}
        </span>
      ),
    },
  ];

  return (
    <>
      <CardWrapper className="accounts-tab shadow glass-card">
        <div className="d-flex justify-content-between pb-2 my-2">
          <h5 className="color-primary">
            {(() => {
              switch (variant) {
                case "MasterDeposit":
                  return t("Master Account Deposits");
                case "InvestorDeposit":
                  return t("Investor Account Deposits");
                case "InvestorWithdraw":
                  return t("Investor Account Withdraws");
                case "FeeTradingWithdraw":
                  return t("Fee Trading Account Withdraws");
                default:
                  return "";
              }
            })()}
          </h5>
        </div>

        {state.loading ? (
          <div className="d-flex align-items-center justify-content-center">
            <Spinner></Spinner>
          </div>
        ) : (() => {
            switch (variant) {
              case "MasterDeposit":
                return state?.masterDeposit?.docs?.length;
              case "InvestorDeposit":
                return state?.investorDeposit?.docs?.length;
              case "InvestorWithdraw":
                return state?.investorWithdraw?.docs?.length;
              case "FeeTradingWithdraw":
                return state?.feeTradingWithdraw?.docs?.length;
              default:
                return "";
            }
          })() > 0 ? (
          <div className="border border-bottom-0 rounded-3 mt-4">
            <Table borderless responsive className="text-center mb-0">
              <Thead className="text-center table-light  border-bottom">
                <Tr>
                  {columns.map((column, index) => (
                    <Th
                      data-priority={index}
                      key={index}
                      className="color-primary"
                    >
                      {column.text}
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <tbody className="text-center">
                {state.loading && <TableLoader colSpan={12} />}
                {!state.loading &&
                  (() => {
                    switch (variant) {
                      case "MasterDeposit":
                        if (state?.masterDeposit?.docs.length > 0) {
                          return state?.masterDeposit?.docs.map(
                            (row, rowIndex) => (
                              <tr key={rowIndex} className="border-bottom">
                                {columns.map((column, index) => (
                                  <td key={`${rowIndex}-${index}`}>
                                    {column.formatter
                                      ? column.formatter(row, rowIndex)
                                      : row[column.dataField]}
                                  </td>
                                ))}
                              </tr>
                            )
                          );
                        }
                        break;
                      case "InvestorDeposit":
                        if (state?.investorDeposit?.docs.length > 0) {
                          return state?.investorDeposit?.docs.map(
                            (row, rowIndex) => (
                              <tr key={rowIndex} className="border-bottom">
                                {columns.map((column, index) => (
                                  <td key={`${rowIndex}-${index}`}>
                                    {column.formatter
                                      ? column.formatter(row, rowIndex)
                                      : row[column.dataField]}
                                  </td>
                                ))}
                              </tr>
                            )
                          );
                        }
                        break;
                      case "InvestorWithdraw":
                        if (state?.investorWithdraw?.docs.length > 0) {
                          return state?.investorWithdraw?.docs.map(
                            (row, rowIndex) => (
                              <tr key={rowIndex} className="border-bottom">
                                {columns.map((column, index) => (
                                  <td key={`${rowIndex}-${index}`}>
                                    {column.formatter
                                      ? column.formatter(row, rowIndex)
                                      : row[column.dataField]}
                                  </td>
                                ))}
                              </tr>
                            )
                          );
                        }
                        break;
                      case "FeeTradingWithdraw":
                        if (state?.feeTradingWithdraw?.docs.length > 0) {
                          return state?.feeTradingWithdraw?.docs.map(
                            (row, rowIndex) => (
                              <tr key={rowIndex} className="border-bottom">
                                {columns.map((column, index) => (
                                  <td key={`${rowIndex}-${index}`}>
                                    {column.formatter
                                      ? column.formatter(row, rowIndex)
                                      : row[column.dataField]}
                                  </td>
                                ))}
                              </tr>
                            )
                          );
                        }
                        break;
                      default:
                        return "";
                    }
                  })()}
              </tbody>
            </Table>
            <div className="my-3">
              {(() => {
              switch (variant) {
                case "MasterDeposit":
                  return <CustomPagination
                    {...state.masterDeposit}
                    setSizePerPage={setSizePerPage}
                    sizePerPage={sizePerPage}
                    onChange={loadDeposits}
                  />;
                case "InvestorDeposit":
                  return <CustomPagination
                    {...state.investorDeposit}
                    setSizePerPage={setSizePerPage}
                    sizePerPage={sizePerPage}
                    onChange={loadDeposits}
                  />;
                case "InvestorWithdraw":
                  return <CustomPagination
                    {...state.investorWithdraw}
                    setSizePerPage={setSizePerPage}
                    sizePerPage={sizePerPage}
                    onChange={loadDeposits}
                  />;
                case "FeeTradingWithdraw":
                  return <CustomPagination
                    {...state.feeTradingWithdraw}
                    setSizePerPage={setSizePerPage}
                    sizePerPage={sizePerPage}
                    onChange={loadDeposits}
                  />;
                default:
                  return "";
              }
            })()}
            </div>
          </div>
        ) : (
          <div className="text-center my-4">
            {(() => {
              switch (variant) {
                case "MasterDeposit":
                  return t("No Master Account Deposits available");
                case "InvestorDeposit":
                  return t("No Investor Account Deposits available");
                case "InvestorWithdraw":
                  return t("No Investor Account Withdraws available");
                case "FeeTradingWithdraw":
                  return t("No Fee Trading Account Withdraws available");
                default:
                  return "";
              }
            })()}
          </div>
        )}
      </CardWrapper>
    </>
  );
}

export default (withTranslation()(CpTransactions));
