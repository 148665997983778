import CardWrapper from "components/Common/CardWrapper";
import PageHeader from "components/Forex/Common/PageHeader";
import { JClickHandler } from "components/Journey/handlers";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Thead } from "react-super-responsive-table";
// eslint-disable-next-line object-curly-newline
import { Button, Container, Row, Spinner, Table } from "reactstrap";
import { getAccountsStart, toggleCurrentModal } from "store/actions";
import { getMamProfilesInitAction } from "store/forex/mamProfile/actions";

const AllocationProfile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { profiles } = useSelector((state) => state.forex.mamProfile);
  const { loading: accountLoading } = useSelector(
    (state) => state.forex?.accounts
  );
  const { portal, subPortal } = useSelector((state) => ({
    portal: state.Layout.portal,
    subPortal: state.Layout.subPortal,
  }));

  const { clientData } = useSelector((state) => ({
    clientData: state.Profile.clientData || {},
  }));

  useEffect(() => {
    dispatch(getMamProfilesInitAction({ params: { type: "sp" } }));
  }, []);

  useEffect(() => {
    dispatch(
      getAccountsStart({
        type: "LIVE",
      })
    );
  }, []);

  const getProfileName = (profileType) => {
    switch (profileType) {
      case 1 : return "Equity Lot Share - MAM";
      case 2 : return "Equity Cash Share - PAM";
      case 9: return "Copy Trading - Social Trading";
      default : return "";
    }
  };

  return (
    <Container className="mt-5">
      <div className="page-content">
        <PageHeader title="Allocation Profile"></PageHeader>
      </div>
      <div className="pt-5">
        <div className="d-flex justify-content-between">
          <h1 className="color-primary">{t("Allocation Profile")}</h1>
          <Button onClick={
            JClickHandler(
              "NewMamProfile",
              clientData?.stages,
              dispatch,
              toggleCurrentModal,
              subPortal,
              portal
            )
          }>
            {t("New Profile")}
          </Button>
        </div>
        <div className="pt-5">
          <CardWrapper className="mt-4 px-5 py-4 glass-card">
            <Row className="border rounded-3">
              <Table
                borderless
                responsive
                hover
                className="cursor-pointer text-center mb-0"
              >
                <Thead className="table-light">
                  <tr>
                    <th className="table-head-item">{t("Master Account")}</th>
                    <th className="table-head-item">{t("Fee Account")}</th>
                    <th className="table-head-item">{t("Allocation Type")}</th>
                    <th className="table-head-item">{t("Periodic Management Fee - ($)")}</th>
                    <th className="table-head-item">{t("Management Fee - ($)")}</th>
                    <th className="table-head-item">{t("Per Lot Fee - ($)")}</th>
                    <th className="table-head-item">{t("Performance Fee - (%)")}</th>
                    <th className="table-head-item">{t("Link Investor")}</th>
                  </tr>
                </Thead>
                <tbody className="border-top">
                  {accountLoading || !profiles ? profiles.map((profile, index) => (
                    <tr
                      key={index}>
                      <td className="py-4" colSpan="100%">
                        <Spinner />
                      </td>
                      <td className="table-body-item">
                        {profile.feeTradingAccountId.login}
                      </td>
                      <td className="table-body-item">
                        {getProfileName(profile.profileType)}
                      </td>
                      <td className="table-body-item">
                        $
                        {profile.feeSetting &&
                          profile.feeSetting.periodicManagementFee}
                      </td>
                      <td className="table-body-item">
                        $
                        {profile.feeSetting && profile.feeSetting.managementFee}
                      </td>
                      <td className="table-body-item">
                        ${profile.feeSetting && profile.feeSetting.perLotFee}
                      </td>
                      <td className="table-body-item">
                        {profile.feeSetting &&
                          profile.feeSetting.performanceFee}
                        %
                      </td>
                      <td className="table-body-item">{profile?.slaves?.length}</td>
                    </tr>
                  )) : profiles.length === 0 ? (
                    <tr>
                      <td colSpan="100%" className="my-2">
                        {t("You currently don't have any profiles.")}
                      </td>
                    </tr>
                  ) : (
                    profiles.map((profile, index) => (
                      <tr
                        key={index}
                        className="border-top"
                        // onClick={() => handleAsccountSelect(profile._id)}
                      >
                        <td className="table-body-item">
                          {profile.masterTradingAccountId.login}
                        </td>
                        <td className="table-body-item">
                          {profile.feeTradingAccountId.login}
                        </td>
                        <td className="table-body-item">
                          {getProfileName(profile.profileType)}
                        </td>
                        <td className="table-body-item">
                          $
                          {profile.feeSetting &&
                            profile.feeSetting.periodicManagementFee}
                        </td>
                        <td className="table-body-item">
                          $
                          {profile.feeSetting && profile.feeSetting.managementFee}
                        </td>
                        <td className="table-body-item">
                          ${profile.feeSetting && profile.feeSetting.perLotFee}
                        </td>
                        <td className="table-body-item">
                          {profile.feeSetting &&
                            profile.feeSetting.performanceFee}
                          %
                        </td>
                        <td className="table-body-item">{profile?.slaves?.length}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </Row>
            {/* <div className="mt-4">
              <CustomPagination
                {...accountsPagination}
                setSizePerPage={setSizePerPage}
                sizePerPage={sizePerPage}
                onChange={handlePageChange}
              />
            </div> */}
          </CardWrapper>
        </div>
      </div>
    </Container>
  );
};

export default AllocationProfile;
